.ag-theme-alpine {
  --ag-borders: solid 1px !important;
  --ag-header-column-separator-display: block !important;
  --ag-header-column-separator-height: 100% !important;
  --ag-header-column-separator-color: black !important;
}

.ag-header-row:first-child .ag-header-group-cell:nth-child(2)
.ag-header-group-cell-label {
  font-size: 25px !important;
  justify-content: center;
}

.ag-header-group-text {
  white-space: normal;
}

.ag-header-cell-label {
  /*Necessary to allow for text to grow vertically*/
  height: 100%;
  padding: 0 !important;
}

.ag-header-cell-label .ag-header-cell-text {
  /*Force the width corresponding at how much width
    we need once the text is laid out vertically*/
  width: 55px;
  writing-mode: vertical-rl;
  line-height: 2em;
  //margin-top: 60px;
}

.ag-pivot-off .ag-header-group-cell,
.ag-pivot-off .ag-header-cell {
  font-size: 12px;
  font-weight: bold;
  white-space: normal;
  background: lightgray;
}

.ag-pivot-off .ag-header-cell-label {
  font-size: 12px;
  height: auto;
  padding-top: 36px;
  margin-left: 0px;
  font-weight: bold;
}

.ag-cell {
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
}

.row-group-header {
  padding: 0 !important;
  font-size: 12px;
  font-weight: bold;
  line-height: 2em;
  white-space: normal;
  writing-mode: vertical-rl;
  background: lightgray;
  border-left: 1px solid black !important;
  border-top: 1px solid black !important;
}

.row-header {
  font-size: 12px;
  font-weight: bold;
  background: lightgray;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
  border-top: 1px solid black !important;
}

.left-title {
  font-size: 25px;
  font-weight: bold;
  writing-mode: vertical-rl;
  background: lightgray;
  text-align: center;
  padding: 0;
}
