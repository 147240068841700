#waste-matrix-container {
  width: 100%;
}

#waste-matrix-container h3 {
  text-align: center;
}

#waste-matrix-container div.dropdown {
  width: 40%;
}

#waste-matrix-container button.dropdown-toggle {
  width: 100%;
}

#waste-matrix-container #waste-matrix-table {
  --ag-font-size: 15px;
  font-size: 19px;
  margin-top: 1.5em;
  margin-right: auto;
  margin-left: auto;
}

.ag-header-group-cell,
.ag-header-cell-text,
.row-header,
.row-group-header {
  font-size: 15px !important;
}

#waste-matrix-container .waste-matrix-content {
  width: 1225px;
  margin-right: auto;
  margin-left: auto;
}

#waste-matrix-container .waste-matrix-legend {
  margin-top: 1.5em;
}
