$menu-item-active-background-color: $primary;

#ecol-assess-container,
#ecol-assess-container-content {
  width: 100%;
}

#ecol-assess-container h1.title {
  text-align: center;
}

#ecol-assess-container #chart-container {
  width: 100%;
  height: 300px;
}

#ecol-assess-container #distribution-chart-container {
  width: 65%;
}

#ecol-assess-container .save-dashboard,
#ecol-assess-container .notification,
#ecol-assess-container article.message {
  margin-left: 10%;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block;
}
