#converter-ecological-assessment h1 {
  text-align: center;
}

#converter-ecological-assessment .save-dashboard,
#converter-ecological-assessment .notification,
#converter-ecological-assessment article.message
{
  margin-left: 10%;
}

#converter-ecological-assessment #chart-container {
  width: 80%;
  height: 300px;
}
