div.dropdown, button.dropdown-toggle {
  width: 100%;
  font-weight: normal;
}

div.dropdown {
  border: solid $primary 1px;
  border-radius: 4px;
}

button.dropdown-toggle {
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 11px;
  text-align: left;
  font-size: 1em;

}
