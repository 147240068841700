#sponsor {
  text-align: right;
}

#sponsor-text {
  margin-bottom: 0.5rem;
}

#logos {
  padding-left: 30%;
  padding-right: 14%;
}

#green-delta-logo {
  max-width: 70%;
}

#skz-logo {
  max-width: 30%;
  margin-right: 2rem;
}

#cirplus-logo {
  max-width: 70%;
}

#wuppertal-logo {
  max-width: 70%;
}

#bmbf-logo {
  max-width: 45%;
}

.col-logo {
  text-align: center;
}

.logo-text {
  font-size: larger;
  text-align: center;
  margin-bottom: 2rem;
}

footer {
  padding: 0.5rem 1rem !important;
  position: fixed;
  bottom: 0;
  width: 100%;
}
