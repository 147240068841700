$success: hsl(153, 53%, 53%);
$primary: hsl(205, 92, 38);
$info: hsl(198, 75, 52);
$column-gap: 0.4rem;
$gap: 200px;

* {
  box-sizing: border-box;
}

.body-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

// Make sure footer will not cover contents
// at the end of a page
.body-padding {
  padding-top: 2.5rem !important;
  padding-bottom: 250px !important;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

// Make sure the selects are the same size.
.select,
.select select {
  width: 100%;
}

.button.nav-button {
  font-size: 1.3rem !important;
  font-weight: bolder;
  color: white;
  width: 300px;
  height: 60px;
}

.button.back-button {
  margin-top: 5rem;
}

.button.econ-assess {
  background-color: hsl(229, 53, 53);
}

.button.ecol-assess {
  background-color: rgb(0, 113, 128);
}

.button.waste-matrix {
  background-color: hsl(198, 75, 52);
}

.button.price-prediction {
  background-color: hsl(205, 92, 38);
}

.dashboard-button {
  margin-top: 3.5em;
}

.dashboard-button button.button.nav-button {
  background-color: rgba(128, 128, 128, 0.825);
  width: auto;
  padding: 2.3em 1.6em;
  border-radius: 25px;
}

.nav-button-wrapper {
  padding: 5px 0;
}

.saved-alert {
  margin-left: 10px;
}

.chart-explain {
  font-size: small;
}

// user-defined scss files
@import './components/Header.scss';
@import './components/Home.scss';
@import './components/Footer.scss';
@import './components/ChooseStakeholder.scss';
@import './components/BuyerGeneralData.scss';
@import './components/BuyerTransportation.scss';
@import './components/EcologicalAssessment.scss';
@import './components/MaterialDropdown.scss';
@import './components/WasteOwnerTools.scss';
@import './components/Recycler.scss';
@import './components/BuyPlasticWaste.scss';
@import './components/SellRecyclates.scss';
@import './components/ConverterProduct.scss';
@import './components/ConverterEcologicalAssessment.scss';
@import './components/ConverterTools.scss';
@import './components/DesignForRecycling.scss';
@import './components/EconomicAssessment.scss';
@import './components/NestedDropdown.scss';
@import './components/PlasticInput.scss';
@import './components/WasteMatrix.scss';
@import './components/WasteMatrixTable.scss';
@import './components/Dashboard.scss';

// external library scss files
// bulma.sass must be imported after user defined files so its Sass variables
// can be overridden by user's variables
@import 'bulma/bulma.sass';
@import 'bulma-steps-component/bulma-steps.sass';
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import 'bulma-tooltip/src/sass/index.sass';
