#home-container {
  margin-left: 15%;
  margin-right: 15%;
}

#home-container .title {
  text-align: center;
}

#start-button {
  margin-top: 2em;
  border-radius: 25px;
}

#home-container #about-text,
#home-container #background-text,
#home-container #disclaimer-text {
  border-radius: 25px;
  border: 3px solid $info;
  padding: 20px;
}

#home-container #about-title,
#home-container #background-title,
#home-container #disclaimer-title {
  position: relative;
  top: 20px;
  font-size: 1.3em;
  font-weight: bold;
  background-color: $info;
  color: white;
  width: auto;
  padding: 3px 25px;
  text-align: center;
  border-radius: 12px;
  border: 3px solid $primary;
}

.col-same-height {
  display: flex !important;
}

.max-height {
  height: 95%;
}
