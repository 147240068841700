#economic-assessment-container {
    width: 100%;
}

#economic-assessment-container h1.title,
#economic-assessment-container h3.title {
    text-align: center;
}

#economic-assessment-container .field {
  margin-bottom: 1.5em;
}

#economic-assessment-container .parameter-influence-select {
  margin-bottom: 1em;
}
