$navbar-height: 2.5rem;
$navbar-item-img-max-height: 2.5rem;
$navbar-item-hover-color: $primary;
$navbar-item-active-color: $primary;
$navbar-dropdown-arrow: $primary;
$navbar-dropdown-item-hover-color: $primary;

.navbar {
  --mdb-navbar-padding-top: 0;
  --mdb-navbar-padding-y: 0;
  --mdb-navbar-brand-padding-y: 0;
}

.navbar .select {
  width: auto;
}

.navbar .select select {
  margin-right: 5px;
}

.navbar label.label {
  margin-bottom: 0;
}
