#dashboard-container {
  width: 100%;
}

#dashboard-container-content {
  width: 100%;
}

#dashboard-container h3 {
  text-align: center;
}

#dashboard-container h3 img {
  max-width: 11%;
}

#dashboard-container #no-content {
  margin: 0 auto;
  text-align: center;
  width: 50%;
}

#dashboard-container #waste-matrix-dashboard {
  width: 100%;
}

#dashboard-container #waste-matrix-dashboard .card-content,
#dashboard-container #ecol-assess-dashboard .card-content {
  padding: 0;
}

#dashboard-container #tips-dashboard {
  width: 100%;
}

#dashboard-container .card-header {
  padding: 0;
}

#dashboard-container #impact-assess-chart {
  height: 300px;
}

#dashboard-container #distribution-chart-container {
  width: 70%;
}

#dashboard-container .parameter-influence-select {
  width: 50%;
  margin-bottom: 1em;
}

#dashboard-container .parameter-influence-select,
#dashboard-container label {
  margin-left: 5em;
}

#dashboard-container .download {
  margin-right: 1rem;
}
