span.right-arrow {
  position: absolute;
  right: 10px;
  font-weight: bold;
}

a.dropdown-item {
  padding-right: 2rem !important;
}

button.btn.dropdown-toggle {
  text-transform: none;
}
